body *{
    font-family: Poppins-Regular !important;
    box-sizing: border-box;
  }

/* dashboard */
.main-div{margin-top:100px;}
.dashboard-outer .dashboard-inner {display: flex;min-height: 100vh;}
.dashboard-outer .dashboard-inner .right-outer{flex: 1;max-width: calc(100% - 288px);}
.dashboard-outer .dashboard-sidebar{padding: 40px 0;box-shadow: 6px 0px 20px 0px rgba(0,0,0,0.05);top: 0;width: 288px; flex: 0 0 288px;overflow-x: auto;}
.dashboard-outer .dashboard-sidebar .close-btn{display: none; position: absolute; right: 0; top:0;padding: 10px;}
.dashboard-outer .dashboard-sidebar .detail-wrap{padding: 0 60px;margin: 0 0 40px; text-align: left;}
.dashboard-outer .dashboard-sidebar .detail-wrap .img-wrap{max-width: 72px;width: 100%;}
.dashboard-outer .dashboard-sidebar .detail-wrap .img-wrap a.avatar-link{text-decoration: none;}
.dashboard-outer .dashboard-sidebar .detail-wrap .avatar-img{width: 72px;height: 72px;border-radius: 10px;font-size: 40px; font-weight: 600;color: #fff;background-color: #444;margin: 0 0 5px;}
.dashboard-outer .dashboard-sidebar .detail-wrap .avatar-img.profile{width: 72px;height: 72px;border-radius: 10px;font-size: 40px; font-weight: 600;background-color: #444;margin: 0 0 5px;color: #F5EA16;border: 2px solid #F5EA16;}
.dashboard-outer .dashboard-sidebar .detail-wrap .title{font-size: 16px; font-weight: 600; color: #444; display: block;margin: 0;}
.dashboard-outer .dashboard-sidebar .detail-wrap .name{font-size: 14px; font-weight: 500; color:#444;opacity: 0.7;display: block;text-decoration: none;}
.dashboard-outer .dashboard-sidebar .list-wrap .list-inner{padding: 0; list-style: none;margin: 0;text-align: left;}
.dashboard-outer .dashboard-sidebar .list-wrap .list-item{margin: 0 0 20px;}
.dashboard-outer .dashboard-sidebar .list-wrap .list-item-link{display: flex;align-items: center; color: #444; text-decoration: none; font-size: 18px; line-height: 22px; font-weight: 500;padding: 12px 60px;position: relative; transition: all 0.3s;text-transform: none; width: 100%; justify-content: flex-start}
.dashboard-outer .dashboard-sidebar .list-wrap .list-item-link i{display: block;height: 24px; width: 24px; margin-right: 8px;}
.dashboard-outer .dashboard-sidebar .list-wrap .list-item-link i .default{display: block;}
.dashboard-outer .dashboard-sidebar .list-wrap .list-item-link i .on-active{display: none;}
.dashboard-outer .dashboard-sidebar .list-wrap .list-item-link.active{font-weight: 600;color: #444; width:"100%" }
.dashboard-outer .dashboard-sidebar .list-wrap .list-item-link.active i .default{display: none;}
.dashboard-outer .dashboard-sidebar .list-wrap .list-item-link.active i .on-active{display: block;}
.dashboard-outer .dashboard-sidebar .list-wrap .list-item-link.active::after{content: '';height: 100%;width: 11px;position: absolute;top: 0;left: 0;border-radius: 0 10px 10px 0;background-color: #F5EA16;transition: all 0.3s;}

.dashboard-outer .right-outer .right-inner{padding: 40px 60px 60px 24px;}
.dashboard-outer .title-wrap{display: flex;align-items: center; justify-content: space-between;flex-wrap: wrap;margin: 0 0 32px;}
.dashboard-outer .title-wrap .title{font-size: 20px; line-height: 1.5; color: #444; opacity: 0.8;font-weight: 500; display: block;}
.dashboard-outer .title-wrap .btn-wrap{display: flex;flex: 1; justify-content:flex-end;}
.dashboard-outer .title-wrap .btn-wrap .icon-btn{border-radius: 10px; background-color: #fff;box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.1);margin-left: 24px;overflow: hidden;position: relative;}
.dashboard-outer .title-wrap .btn-wrap .icon-btn *{border-radius: 10px !important;}
.dashboard-outer .title-wrap .btn-wrap .icon-btn .status-img{position: absolute;top: 5px;right: 8px;}
.dashboard-outer .title-wrap .btn-wrap .icon-btn.hamburger-btn{display: none;}

.custom-button{font-size:14px!important;background-color:transparent!important;border:1px solid transparent!important;border-radius:20px!important;font-weight:600!important;min-height:37px!important;height:auto!important;padding:5px 15px!important;text-transform:none!important;letter-spacing:normal!important;line-height:1!important;min-width:115px!important}
.custom-button.outline-yellow{background-color:#fff!important;border-color:#f5ea16!important;color:#000!important}
.custom-button.outline-yellow .MuiTouchRipple-child{background-color:rgb(245,234,22,.5)}
.custom-button.font{font-size:16px!important;background-color:transparent!important;border:1px solid transparent!important;border-radius:20px!important;font-weight:600!important;min-height:37px!important;height:auto!important;padding:5px 15px!important;text-transform:none!important;letter-spacing:normal!important;line-height:1!important; min-width: 115px !important;}
.custom-button.outline-yellow.font{background-color:#fff!important;border-color:#f5ea16!important;color:#555!important;font-weight: 500 !important;}
.custom-button.outline-yellow.font .MuiTouchRipple-child{background-color:rgb(245,234,22,.5)}
.custom-button.outline-black{background-color:#fff!important;border-color:#444!important;color:#444!important;font-weight: 500 !important;}
.dashboard-outer .title-wrap .right-wrap{flex: 1;}
.progress-wrap .progress-inner{display: flex; flex-wrap: wrap; align-items: center; border: 1px solid rgba(68, 68, 68,0.1);padding: 16px 24px; border-radius: 10px;margin: 0 0 40px;}
.progress-wrap .progress-inner .progress-img-wrap{flex: 0 0 100px; max-width: 100px;height: 100px;}
.progress-wrap .progress-inner .progress-img-wrap .inner-img-wrap{position: relative;height: 100%;}
.progress-wrap .progress-inner .progress-img-wrap .progress-circle{ color: #F5EA16; position: absolute;top: 0;left: 0;}
.progress-wrap .progress-inner .progress-img-wrap .bottom-circle{color: #444;opacity: 0.2;}
.progress-wrap .progress-inner .progress-img-wrap .value{position: absolute;top: 0;left: 0;width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;font-size: 20px; line-height: 1; font-weight: 700;color: #444;}
.progress-wrap .progress-inner .content-wrap{flex: 1;text-align: left;padding-left: 16px;}
.progress-wrap .progress-inner .content-wrap span{font-size: 14px; color: #444;line-height: 24px;display: block;margin: 0 0 8px;font-weight: 500;max-width: 400px;width: 100%;}
.progress-wrap .progress-inner .content-wrap a{font-size: 16px; color: #444;line-height: 24px;display: block;font-weight: 600;}
.progress-cards-wrap {margin: 0 0 40px;}
.progress-cards-wrap .progress-cards-inner{display: flex;flex-wrap: wrap; margin: 0 -12px -24px;}
.progress-cards-wrap .progress-cards-inner .card-item{flex: 0 0 25%; max-width: 25%;margin: 0 0 24px;padding: 0 12px;}
.progress-cards-wrap .progress-cards-inner .card-item .card-inner{overflow: hidden; background-color: #fff;box-shadow: 0px 0px 20px 1px rgba(0,0,0,0.1);border-radius: 10px;padding: 0 20px;height: 100%;display: flex;flex-direction: column;}
.progress-cards-wrap .progress-cards-inner .card-item .card-inner .img-wrap{height: 200px; padding: 10px 0 15px;}
.progress-cards-wrap .progress-cards-inner .card-item .card-inner .content-wrap{height: 100%;display: flex;flex-direction: column;}
.progress-cards-wrap .progress-cards-inner .card-item .card-inner .content-wrap > span{font-size: 16px; line-height: 20px; font-weight: 500; color: #444; display: block; text-align: left;margin: 0 0 8px;}
.progress-cards-wrap .progress-cards-inner .card-item .card-inner .content-wrap .icon-btn{background-color: #F5EA16;margin:auto -20px 0 auto; border-radius: 10px 0 0 0;width: 65px;height: 37px;padding: 4px;}

.business-analytics{margin: 0 0 40px;}
.business-analytics .business-baner{background-color: #FBC90A;position: relative;overflow: hidden;border-radius: 10px;}
.business-analytics .business-baner .banner-img-content{display: flex;align-items: center;margin: 42px 21px;}
.business-analytics .business-baner .banner-img-content .business-anounce-wrap{max-width: 56px;max-height: 56px;width: 100%;height: 100%;}
.business-analytics .business-baner .banner-img-content .business-anounce-wrap img{height: 100%;width: 100%;display: block;}
.business-analytics .business-baner .banner-pattern .business-pattern-wrap{max-width: 232px;position: absolute;right: 0;top: 0;height: 100%;width: 100%;}
.business-analytics .business-baner .banner-pattern .business-pattern-wrap img{height: 100%;width: 100%;display: block;object-fit: cover;}
.business-analytics .business-baner span{color: #444;font-size: 16px;font-weight: 600;line-height: normal;max-width: 300px;width: 100%;margin-left: 16px;display: block;}
.business-analytics .business-overview{margin-top: 32px;}
.business-analytics .business-overview .overview-title span{color: #555;font-size: 20px;font-weight: 600;}
.business-analytics .business-overview .overview-table-data{margin-top: 24px;}
.business-analytics .business-overview .overview-table-data .outer-border{max-width: 525px;max-height: 430px;height: 100%;width: 100%;border-radius: 10px;border: 1px solid rgba(68, 68, 68, 0.10);padding: 8px 24px 0 16px;overflow: auto;}
.business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data{max-width: 485px;width: 100%;background-color: #F6F6F6;stroke-width: 1px;stroke: rgba(68, 68, 68, 0.10);filter: drop-shadow(0px 6px 16px rgba(178, 177, 177, 0.10));padding: 8px 24px;margin-top: 16px;border: 1px solid #E6E6E6;border-radius: 10px;}
.business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data:last-child{margin-bottom: 24px;}
.business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data .data-title span{color: #555;font-size: 16px;font-weight: 500;line-height: 1.2;}
.business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data .number-data{display: flex;margin-top: 12px;}
.business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data .number-data .number-data-content{flex: 1;}
.business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data .number-data .number-data-content:not(:first-child){border-left: 1px solid rgba(68, 68, 68, 0.10);}
.business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data .number-data .number-data-content:not(:first-child) .inner-content{display: flex;flex-wrap: wrap;flex-direction: column; align-items: flex-start; padding: 0 20px; max-width: 175px; margin: 0 auto;}
.business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data .number-data .title{color: rgba(68, 68, 68, 0.70);font-size: 14px;font-weight: 600;line-height: normal;display: block;}
.business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data .number-data .content{color: #444;font-size: 20px;font-weight: 600;line-height: normal;display: block;margin-top: 8px;}


.video-questions-block{text-align: left}
.video-questions-block .btn-wrap .custom-button button .MuiButton-label{font-size: 16px !important;}
.video-questions-block .btn-wrap {margin: 0 0 20px;}
.video-questions-block .btn-wrap .custom-button:not(:last-child){margin-right: 20px;}
.video-questions-block .btn-wrap .custom-button i{display: block; height: 18px; width: 18px;margin-right: 10px;}
.video-cards-wrap{margin: 0 0 40px;}
.video-cards-wrap .video-cards-inner{display: flex;flex-wrap: wrap;margin: 0 -12px -24px;}
.video-cards-wrap .card-item{padding: 0 30px; margin: 0 0 24px; flex: 0 0 25%; max-width: 25%;}
.video-cards-wrap .card-item .img-wrap{position: relative;}
.video-cards-wrap .card-item .img-wrap .inner-img-wrap > img{width: calc(100% + 30px); height: 150px; margin:0 -15px ;}
.video-cards-wrap .card-item .img-wrap .icon-btn{position: absolute;top: 50%; left: 50%; transform: translate(-50%,-50%); z-index: 1; height: 44px;width: 44px;padding: 4px;}
.video-cards-wrap .card-item .content-wrap .title{font-size: 16px;line-height: 20px; color: #444; display: block;font-weight: 500;margin: 15px 0 8px;cursor: pointer;}
.video-cards-wrap .card-item .content-wrap .time{font-size: 14px;line-height: 18px; color: #444; display: flex;align-items: center; opacity: 0.7;}
.video-cards-wrap .card-item .content-wrap .time i{display: block;height: 16px;width: 16px; margin-right: 5px;}

.accordion-wrap{margin: 0 0 40px;}
.custom-accordion{box-shadow: none !important; border-radius: 0 !important;background-color: rgba(245, 234, 22,0.1) !important; margin: 0 0 16px; text-align: left; }
.custom-accordion::before{display: none;}
.custom-accordion .accordion-head{font-size: 18px; line-height: 30px; color: #444; font-weight: 500;padding: 0 40px 0 16px;}
.custom-accordion .accordion-head .arrow{margin-right: 16px;width: 40px; min-width: 40px; height: 40px; display: flex; align-items: center; justify-content: center;transition: all 0.3s;}
.custom-accordion .accordion-body{font-size: 18px; line-height: 30px; color: #444;padding: 0 40px 16px 70px;}
.custom-accordion.Mui-expanded .accordion-head .arrow{transform: rotate(180deg);}
.accordion-wrap .MuiPaper-elevation1{box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15) !important; border-radius: 8px;}
.accordion-poppular.Mui-expanded .accordion-head .arrow{transform: rotate(270deg);}
.accordion-wrap .MuiAccordion-root{position: inherit;}
.accordion-wrap .MuiAccordionDetails-root{display: block;}
.accordion-details .MuiPaper-elevation1{box-shadow: none !important;margin: 0 !important;}
.accordion-details .MuiAccordionSummary-root{max-height: 1px !important;min-height: 20px;}
.accordion-details .MuiAccordionSummary-root.Mui-expanded{min-height: 20px;}
.accordion-body.MuiAccordionDetails-root{padding: 0;margin-bottom: 20px;}
.accordion-body-about.MuiAccordionDetails-root{margin-bottom: 20px;}
.accordion-details .MuiAccordionSummary-content{margin: 0;}
.custom-dialog.congratulations-dialog .MuiDialog-paper{max-width: 573px;}
.custom-dialog.congratulations-dialog .dialog-content{padding: 0;overflow: hidden;}
.custom-dialog.congratulations-dialog .dialog-content .img-wrap{margin-bottom: -40px;}
.custom-dialog.congratulations-dialog .dialog-content .img-wrap img{width: 100%;}
.custom-dialog.congratulations-dialog .dialog-content .content-wrap{padding: 0 58px 40px; text-align: center;}
.custom-dialog.congratulations-dialog .dialog-content .content-wrap .title{font-size: 24px;font-weight: 600;line-height: 36px; color: #555;display: block;margin: 0 0 12px;}
.custom-dialog.congratulations-dialog .dialog-content .content-wrap .content{font-size: 16px;color: rgba(68, 68, 68,0.7);display: block;}

@media(max-width:1299px){
    .progress-cards-wrap .progress-cards-inner .card-item{flex: 0 0 33.33%;max-width:33.33%;}
    .video-cards-wrap .card-item{flex: 0 0 33.33%; max-width: 33.33%;}
}

@media (max-width:1180px){    
.main-div{margin-top: 65px;}
.progress-cards-wrap .progress-cards-inner .card-item{flex: 0 0 50%;max-width:50%;}
.video-cards-wrap .card-item{flex: 0 0 50%; max-width: 50%;}
.video-cards-wrap .card-item .img-wrap .inner-img-wrap > img{height: 180px;}
.dashboard-outer .right-outer .right-inner{padding: 25px;}
.custom-accordion .accordion-head{font-size: 16px; line-height: 22px; padding: 0 16px;}
.custom-accordion .accordion-head .arrow{width: 30px; min-width: 30px; height: 30px;padding: 10px;}
.custom-accordion .accordion-head .arrow img{width: 18px;}
.custom-accordion .accordion-body{padding: 0 16px 16px 60px; font-size: 16px; line-height: 22px;}
}
@media (max-width:1024px){    
.main-div{margin-top: 65px;}
}

@media (max-width:991px){
    .dashboard-outer .dashboard-sidebar{position: fixed;left: -100%;background-color: #fff;z-index: 100;transition: all 0.5s;height: calc(100vh - 65px);}
    .dashboard-outer .dashboard-sidebar.sidebar-open{left: 0;}
    .dashboard-outer .title-wrap .btn-wrap .icon-btn.hamburger-btn{display: block;}
    .dashboard-outer .dashboard-sidebar .close-btn{display: block;}
    .main-div{margin-top: 65px;}
    .dashboard-sidebar{margin-top:65px;}
    .dashboard-outer .dashboard-inner .right-outer{max-width: 100%;}
}

@media (max-width:767px){
    .main-div{margin-top: 65px;}
    .dashboard-sidebar{margin-top:65px;}
    .custom-dialog.congratulations-dialog .dialog-content .img-wrap{margin-bottom: 0;}
    .custom-dialog.congratulations-dialog .dialog-content .content-wrap .title{font-size: 18px;line-height: 22px;}
    .custom-dialog.congratulations-dialog .dialog-content .content-wrap{padding: 0 15px 20px;}
}

@media (max-width:676px) {
    .business-analytics .business-baner .banner-pattern .business-pattern-wrap{max-width: 80px;}
}

@media (max-width:525px) {
    .business-analytics .business-baner span{max-width: calc(100% - 125px);}
    .business-analytics .business-baner .banner-img-content{margin: 30px 15px;}
}

@media (max-width:393px) {
    .business-analytics .business-baner .banner-img-content{margin: 20px 10px;}
    .business-analytics .business-baner .banner-pattern .business-pattern-wrap{display: none;}
    .dashboard-outer .dashboard-sidebar{width: 242px; flex: 0 0 242px;}
}

@media (max-width:575px){
    .main-div{margin-top: 65px;}
    .dashboard-sidebar{margin-top: 65px;}
    .dashboard-outer .title-wrap .title{margin-bottom: 15px;}
    .progress-cards-wrap .progress-cards-inner .card-item{flex: 0 0 100%;max-width:100%;}
    .progress-wrap .progress-inner{padding: 16px 20px;}
    .video-cards-wrap .card-item{flex: 0 0 100%; max-width: 100%;}
}

@media (max-width:515px){
    .business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data .number-data .number-data-content .inner-content{height: 100%;display: flex;flex-wrap: wrap;flex-direction: column;align-items: flex-start;max-width: 175px;margin: 0 auto;}
    .business-analytics .business-overview .overview-table-data .outer-border .inner-wrap .inner-content .table-data .number-data .content{margin-top: auto;}
}

@media (max-width:394px){
    .video-questions-block .btn-wrap .custom-button:not(:last-child){margin-right: 15px;}
}

@media (max-width:389px){
    .video-questions-block .btn-wrap .custom-button:not(:last-child){margin-right: 10px;}
}

@media (max-width:384px){
    .video-questions-block .btn-wrap .custom-button:not(:last-child){margin-right: 10px;}
    .custom-button{font-size: 11px !important;}
    .custom-button.font{font-size: 13px !important;}
}

@media (max-width:374px){
    .main-div{margin-top: 65px;}
    .dashboard-sidebar{margin-top: 65px;}
    .progress-wrap .progress-inner{flex-direction: column;}
    .progress-wrap .progress-inner .progress-img-wrap{margin: 0 0 10px;}
    .progress-wrap .progress-inner .content-wrap{padding: 0;}
    .video-questions-block .btn-wrap .custom-button:not(:last-child){margin-right: 10px;}
}

@media (max-width:343px){
    .custom-button{font-size: 10px !important;}
    .custom-button.font{font-size: 12px !important;}
    .video-questions-block .btn-wrap .custom-button:not(:last-child){margin-right: 5px;}
}

@media (max-width:343px){
    .video-questions-block .btn-wrap .custom-button:not(:last-child){margin-right: 0px;}
}

@media (max-width:330px){
    .dashboard-outer .title-wrap .btn-wrap .icon-btn{
        margin-left: 10px;
    }
}