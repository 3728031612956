.container{max-width:1356px;margin:0 auto;width:100%;padding:0 25px}
.custom-button.outline-red{background-color:#fff!important;border-color:#EC2024!important;color:#555!important}
.custom-button.outline-red .MuiTouchRipple-child{background-color:rgba(245, 22, 22, 0.5)}
.main-div {margin-top: 100px;}
.banner-section-seller{margin-bottom:40px}
.banner-section-seller .edit-icon{position:absolute;background-color:#444!important;padding:0;width:24px;height:24px;cursor:pointer;border-radius:100%}
.banner-section-seller .edit-icon-transparant{position:absolute;background-color:transparent!important;padding:0;width:24px;height:24px;cursor:pointer;border-radius:100%}
.banner-section-seller .edit-icon img{height:12px!important;width:12px!important}
.banner-section-seller .edit-icon-transparant img{height:24px!important;width:24px!important}
.banner-section-seller .edit-icon .upload{position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;cursor:pointer;z-index:1}
.banner-section-seller .edit-icon-transparant .upload{position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;cursor:pointer;z-index:1}
.banner-section-seller .banner-img-wrap-seller{position:relative;height:175px;}
.banner-section-seller .banner-img-wrap-seller.no-bg{border: 1px solid rgba(68, 68, 68, 0.10);background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #000 155.31%)}
.banner-section-seller .banner-img-wrap-seller.no-bg img{object-fit:none;}
.banner-section-seller .banner-img-wrap-seller img{width:100%;object-fit:cover;height:100%}
.banner-section-seller .banner-img-wrap-seller .edit-icon{right:50px;bottom:14px}
.banner-section-seller .banner-img-wrap-seller .edit-icon-transparant{right:50px;bottom:14px}
.banner-section-seller .profile-detail-seller .profile-detail-inner-seller{display:flex;margin-top:-77px;position:relative;padding: 0 24px;}
.banner-section-seller .profile-detail-seller .profile-photo-wrap-seller{max-width:100px;flex:0 0 100px;height:100px;margin-right:27px;position:relative}
.banner-section-seller .profile-detail-seller .profile-photo-wrap-seller .profile-photo-seller{ outline: 4px solid white; width:100%;height:100%;border-radius:100%}
.banner-section-seller .profile-detail-seller .profile-photo-wrap-seller .edit-icon{bottom:4px;right:8px;border:2px solid #f6f6f6}
.banner-section-seller .profile-detail-seller .profile-description-wrap{flex:1;padding:25px 0 0}
.banner-section-seller .profile-detail-seller .profile-description-wrap .name-wrap{display:flex;align-items:center;margin:0 0 20px; justify-content: space-between;}
.banner-section-seller .profile-detail-seller .profile-description-wrap .name{font-size:20px;line-height:30px;font-weight:600;display:block;color:#fff;letter-spacing:.25px;margin-right:10px}
.banner-section-seller .profile-detail-seller .profile-description-wrap .edit-icon-right{position: absolute;right: 48px;top: 30px;}
.banner-section-seller .profile-detail-seller .profile-description-wrap .review{font-size:16px;line-height:23px;display:inline-flex;align-items:center;color:#fff}
.banner-section-seller .profile-detail-seller .profile-description-wrap .review i{display:block;padding-left:8px}
.banner-section-seller .profile-detail-seller .profile-description-wrap .review img{height:16px;width:16px}

.following-section-seller .following-followers-wrap{display:flex; margin: 0 10px;}
.following-section-seller .following-followers-wrap .title-number-wrap{cursor: pointer; display: flex; flex-direction: column; gap: 5px;}
.following-section-seller .following-followers-wrap .title-number-wrap:not(:last-child){margin-right:93px}
.following-section-seller .following-followers-wrap .title{font-size:18px;line-height:22px;color:#444;font-weight:600;display:block;text-align:center}
.following-section-seller .following-followers-wrap .number{font-size:16px;line-height:18px;color:#444;font-weight:600;display:block;text-align:center}
.following-section-seller .business-service-wrap{margin: 16px 0;}
.following-section-seller .business-service-wrap .inner-wrap{display: flex;overflow-x: auto;flex-wrap: wrap;}
.following-section-seller .business-service-wrap .business-facility-main{
    width: 70px;
    min-width: 71px;
    text-align: center;
    margin-bottom:15px;
}
.following-section-seller .business-service-wrap .business-facility{
    position: relative;
    width: 56px;
    height: 56px;
    min-width: 56px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

}
.following-section-seller .business-service-wrap .business-facility img{width: 100%;height: 100%;}
.following-section-seller .business-service-wrap .business-facility .business-text{position: absolute;color: #444;font-size: 6.327px;font-weight: 500;line-height: 1.2;margin-top: 9px;}
.following-section-seller .business-service-wrap .business-facility-main:not(:last-child){margin-right: 55px;}
.following-section-seller .business-service-wrap .business-facility-main > span{color: #444;font-size: 12px;font-weight: 500;line-height: normal;font-family: 'Poppins' !important;}
.following-section-seller .business-content-wrap{max-width: 500px;width: 100%;}
.following-section-seller .business-content-wrap > p{color: #444;font-weight: 400;line-height: normal;font-size: 16px;font-family: 'Poppins' !important;}
.following-section-seller .btn-wrap{display: flex;flex-wrap: wrap; align-items: center; justify-content: space-between;margin: 15px 0 0;max-width: 370px;width: 100%;}
.following-section-seller .btn-wrap > *{margin-right: 10px;margin-bottom: 15px;}
.following-section-seller .bio-btns{justify-content: start; max-width: unset;}
.following-section-seller .bio-btns>*{margin-right: 5px;}
.following-section-seller .stories-highlights-wrap{margin: 40px 0;}
.following-section-seller .stories-highlights-wrap.m-0{margin: 0;}
.highlights-heading{font-weight: 600; margin-top: 10px; margin-bottom: 15px;}
.following-section-seller .stories-highlights-wrap .inner-wrap{display: flex;overflow-x: auto;padding-bottom: 20px;}
.following-section-seller .stories-highlights-wrap .story-outer{display: flex; flex-direction: column; align-items: center; justify-content: center;}
.following-section-seller .stories-highlights-wrap .story:not(:last-child){margin-right: 30px;}
.following-section-seller .stories-highlights-wrap .story-outer:not(:last-child){
    margin-right: 30px;
}
.following-section-seller .stories-highlights-wrap .story{
    position: relative;
    width: 72px;
    height: 72px;
    min-width: 71px;
    background: linear-gradient(white, white) padding-box, linear-gradient(to right, #EB3AA7, #6649D9) border-box;
    border-radius: 100%;
    border: 1px solid transparent;
    padding: 4px;
}
.following-section-seller .stories-highlights-wrap .story .profile-photo{width:100%;height:100%;border-radius:100%}
.following-section-seller .stories-highlights-wrap .edit-icon-story{bottom:0;right:0;}
.following-section-seller .stories-highlights-wrap .story-new{
    position: relative;
    width: 72px;
    height: 72px;
    min-width: 71px;
    background-color: #E9E9E9;
    border-radius: 100%;
    border: 1px solid transparent;
    padding: 4px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
}
.story video{
    object-fit: cover;
    border-radius: 50%;
}
.following-section-seller .stories-highlights-wrap .story-outer p.story-new-text{color: #444;font-size: 12px;font-weight: 400;line-height: 1.5;}
.following-section-seller .profile-link{color: #4D78E4; display: flex; align-items: center; gap: 5px;}
.following-section-seller .decoration-none{text-decoration: none;}
.seller-tabs-list.MuiTabs-root{border: 1px solid #DDDDDD;border-radius: 8px; min-height: auto;padding: 5px;}
.seller-tabs-list .MuiTab-root{padding: 5px;min-width: auto;height: auto;min-height: auto;font-size: 16px;color: #444;text-transform: none;font-weight: 400;opacity: 1;flex: 0 0 50%;max-width: 50%;}
.seller-tabs-list .MuiTab-root.Mui-selected{font-weight: 600;background-color: #f5ea16;border-radius: 8px;}
.seller-tabs-list .MuiTabs-indicator{display: none;}
.seller-tabs-list .MuiTabs-scroller{overflow: auto !important;}
.seller-tabs-list .MuiTabs-flexContainer{display: flex; padding: 0 30px;}

.tabs-wrap .seller-tabs-content .seller-inner-tab-content .seller-inner-tab-wrap{
    margin: 17px 0;
}

.dashboard-outer .dashboard-inner .right-outer .right-inner .right-wrap .btn-wrap .account-list{display: flex;align-items: center;}
.dashboard-outer .dashboard-inner .right-outer .right-inner .right-wrap .btn-wrap .account-list .account-title{color: #444;font-size: 16px;font-weight: 500;line-height: 1.3;padding-right: 5px;}
.dashboard-outer .dashboard-inner .right-outer .right-inner .right-wrap .btn-wrap .account-list .account-list-icon .MuiSvgIcon-root{color: #444;}
.account-list-popover .MuiPopover-paper.MuiPaper-rounded{border-radius: 10px;}
.account-list-details{background-color: #fff;width: 228px;
    /* height: 208px; */
    padding: 15px 10px;border-radius: 10px;overflow: auto;}
.account-list-details .person-detail-wrap{display: flex;padding: 9px 0; align-items: center;}
.account-list-details .person-detail-wrap .img-wrap{width: 48px;height: 48px;min-width: 48px; border-radius: 100%;}
.account-list-details .person-detail-wrap .img-wrap img{width: 40px;height: 40px;border-radius: 50%;}
.account-list-details .person-detail-wrap .detail{flex: 1;}
.account-list-details .person-detail-wrap .detail .title{color: #444;font-size: 12px;font-weight: 600;line-height: 1.2;display: block;}
.account-list-details .person-detail-wrap .detail .name{color: rgba(68, 68, 68, 0.80);font-size: 12px;font-weight: 400;line-height: 1.2;display: block;}
.account-list-details .person-detail-wrap .account-select{width: 20px;height: 20px;}
.account-list-details .person-detail-wrap .account-select img{width: 18px;height: 18x;}

.tab-user-business-section .MuiTabs-flexContainer button.Mui-selected span.MuiTab-wrapper{color: #444;font-size: 16px;font-weight: 600;}
.tab-user-business-section .MuiTabs-flexContainer span.MuiTab-wrapper{color: #444;font-size: 16px;font-weight: 400;}
.tab-user-business-section .tabs-content .user-bio-wrap{display: flex;flex-direction: row; margin: 30px 0;}
.tab-user-business-section .tabs-content .user-bio-wrap .user-list{flex: 0 0 65%;max-width: 65%;}
.tab-user-business-section .tabs-content .user-bio-wrap .user-list input.MuiInputBase-input{color: #555;font-size: 16px;font-weight: 600;line-height: 1.5;}
.tab-user-business-section .tabs-content .user-bio-wrap .user-list .inner-wrap{border-radius: 20px;box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);padding: 24px;}
.tab-user-business-section .tabs-content .seller-business-banking .user-bio-wrap .user-list .editable-form-group > span.field-detail{display: block;}
.tab-user-business-section .tabs-content .user-bio-wrap .bio-list{flex: 0 0 35%;max-width: 35%;}
.tab-user-business-section .tabs-content .user-bio-wrap .bio-list .inner-wrap div.MuiFormControl-root.MuiTextField-root.editable-control.no-underline.small textarea{color: rgba(84, 84, 84, 0.7);font-size: 14px;font-weight: 400;line-height: 30px;}
.tab-user-business-section .tabs-content .user-bio-wrap .bio-list .inner-wrap{border-radius: 20px;box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);margin: 0 0 0 25px;padding: 24px;}
.tab-user-business-section .tabs-content .user-bio-wrap .bio-list .inner-wrap .editable-form-group .editable-label.large{font-size:16px;line-height:20px;width: 100%;}
.user-list .editable-form-group .editable-label{font-size:16px;line-height:20px;color:#555;margin:0 0 10px;width:100%;display:inline-flex;justify-content:space-between;align-items:flex-end}
.tab-user-business-section .tabs-content .seller-user-password{margin: 25px 0;}
.tab-user-business-section .tabs-content .seller-user-password span.passowrd-content{color: rgba(85, 85, 85, 0.70);font-size: 16px;font-weight: 400;}
.tab-user-business-section .tabs-content .seller-user-password .editable-form-group .editable-label.large{display: block;}
.tab-user-business-section .tabs-content .seller-user-password .editable-form-group.column{flex:0 0 46%;max-width:61%;margin:0 0 30px}
.tab-user-business-section .tabs-content .seller-user-password .editable-form-group .btn-wrap{margin: 24px 0;}
.tab-user-business-section .tabs-content .seller-account-removal{margin: 25px 0;}
.tab-user-business-section .tabs-content .seller-account-removal span.account-removal-content{color: rgba(85, 85, 85, 0.70);font-size: 16px;font-weight: 400;}
.tab-user-business-section .tabs-content .seller-account-removal .editable-form-group .editable-label.large{display: block;}
.tab-user-business-section .tabs-content .seller-account-removal .editable-form-group.column{flex:0 0 46%;max-width:61%;margin:0 0 30px}
.tab-user-business-section .tabs-content .seller-account-removal .editable-form-group .btn-wrap{margin: 24px 0;display: flex;flex-wrap: wrap; align-items: center; justify-content: space-between;max-width: 350px;width: 100%;}
.tab-user-business-section .tabs-content .seller-line{border-bottom:1px solid #000; max-width: 65%;}

.tab-user-business-section .tabs-content .seller-business-banking{margin: 25px 0;}
.tab-user-business-section .tabs-content .seller-business-banking .editable-form-group label.editable-label.large{color: #444;font-weight: 600;font-size: 18px;}
.tab-user-business-section .tabs-content .seller-business-banking .editable-form-group .editable-label.large{display: block;}
.tab-user-business-section .tabs-content .seller-business-banking .editable-form-group.column{flex:0 0 46%;max-width:61%;margin:0 0 16px}
.tab-user-business-section .tabs-content .seller-business-banking .user-bio-wrap .user-list .editable-form-group > button{display: block;}
.tab-user-business-section .tabs-content .seller-business-banking .user-bio-wrap .user-list .editable-form-group .upload{position: absolute;left: 0;right: 0;bottom: 0;top: 0;cursor: pointer;z-index: 1;opacity: 0;}
.tab-user-business-section .tabs-content .seller-business-banking{margin: 25px 0;}
.tab-user-business-section .tabs-content .seller-business-banking .editable-form-group .editable-label.large{display: block;}
.tab-user-business-section .tabs-content .seller-business-banking .user-bio-wrap .user-list .editable-form-group > button{display: block;}
.tab-user-business-section .tabs-content .seller-business-banking .user-bio-wrap .user-list .editable-form-group .upload{position: absolute;left: 0;right: 0;bottom: 0;top: 0;cursor: pointer;z-index: 1;opacity: 0;}

.tab-user-business-section .tabs-content .seller-business-legal-wrap .user-bio-wrap .user-list .editable-form-group > span.field-detail{display: block;}
.tab-user-business-section .tabs-content .seller-business-legal-wrap .file-success-wrap{display: flex; align-items: center;}
.tab-user-business-section .tabs-content .seller-business-legal-wrap .file-details > span{display: block; margin: 0 12px;}
.tab-user-business-section .tabs-content .seller-business-legal-wrap .file-details span.file-name{color: #444;font-size: 16px;font-weight: 500;}
.tab-user-business-section .tabs-content .seller-business-legal-wrap .file-details span.file-size{color: #444;font-size: 12px;font-weight: 300;}
.tab-user-business-section .tabs-content .banking-legal-wrap{display: flex;flex-direction: row;}
.tab-user-business-section .tabs-content .banking-legal-wrap .left-side-wrap{flex: 0 0 60%;max-width: 60%;}
.tab-user-business-section .tabs-content .banking-legal-wrap .banking-list-wrap .banking-list{border-radius: 20px;box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);padding: 24px;}
.tab-user-business-section .tabs-content .banking-list-wrap .banking-list .editable-form-group .editable-label.seller-edit{display: flex;justify-content: space-between;align-items: center;width: 100%;}
.tab-user-business-section .tabs-content .seller-business-legal-wrap .business-legal-wrap .left-inner{border-radius: 20px;box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);padding: 24px;}
.tab-user-business-section .tabs-content .seller-business-legal-wrap .business-legal-wrap .left-inner .editable-form-group > span.field-detail{display: block;color: #555;font-size: 16px;font-weight: 600;}
.tab-user-business-section .tabs-content .seller-business-legal-wrap .business-legal-wrap .left-inner .editable-form-group .editable-label.category{display: flex;justify-content: space-between;align-items: center;width: 100%;}
.tab-user-business-section .tabs-content .seller-business-legal-wrap .seller-business-legal{margin-top: 24px;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .banking-list .editable-form-group > span.field-detail{display: block;color: #555;font-size: 16px;font-weight: 600;}
.tab-user-business-section .tabs-content .banking-legal-wrap .shipping-details{flex: 0 0 40%; max-width: 40%;}
.tab-user-business-section .tabs-content .banking-legal-wrap .shipping-details .ship-provider label.editable-label.large{color: #444;font-size: 16px;font-weight: 600;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details{margin: 0 24px;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .shipping-address{margin: 20px 0;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .shipping-address .ship-address-content{display: flex;justify-content: space-between;align-items: center;color: #555;font-size: 14px;font-weight: 400;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .pickup-address{margin-top: 24px;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .pickup-address .right-wrap{border-radius: 20px;box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);padding: 24px;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .pickup-address .right-wrap label.editable-label.large{color: #555;font-size: 16px;font-weight: 400;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .pickup-address .editable-form-group .editable-label.large{display: flex;justify-content: space-between;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .pickup-address .right-wrap textarea{color: #555; font-size: 16px;font-weight: 600;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .pickup-address .editable-form-group .pickup-address-switch span.switch-title{color: #555;font-weight: 400;font-size: 14px;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .pickup-address .editable-form-group .pickup-address-switch{display: flex;justify-content: space-between;align-items: center;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .pickup-address .editable-form-group .pickup-address-switch.second{margin-top: 30px;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .ship-radio-input .MuiFormGroup-root{display: flex;flex-wrap: wrap;flex-direction: row;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .ship-radio-input .MuiRadio-colorSecondary.Mui-checked{color: #F5EA16;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .shipping-details .ship-radio-input label.MuiFormControlLabel-root span.MuiTypography-body1{color: #444;font-size: 16px;font-weight: 400px;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .account-wrap{display: flex;justify-content: space-between;width: 100%;}
.tab-user-business-section .tabs-content .seller-business-banking .banking-legal-wrap .account-wrap .plus-edit-icon{display: inline-flex;}

.seller-tabs-content .post-cards-wrap .inner-wrap .content-wrap .img-content-wrap{display: flex;}
.seller-tabs-content .post-cards-wrap .inner-wrap .content-wrap .img-content-wrap .liked{color: #444;font-size: 13px;font-weight: 400;line-height: 18px;letter-spacing: -0.07px;margin-left: 12px;}
.seller-tabs-content .post-cards-wrap .inner-wrap .content-wrap .img-content-wrap .liked .liked-bold{color: #444;font-size: 13px;font-weight: 600;line-height: 18px;letter-spacing: -0.07px;}
.seller-tabs-content .post-cards-wrap .inner-wrap .content-wrap .post-content{color: #444;font-size: 13px;font-weight: 400;line-height: 18px;letter-spacing: -0.07px;margin:7px 0;}
.seller-tabs-content .post-cards-wrap .inner-wrap .content-wrap .post-content .liked-bold{color: #444;font-size: 13px;font-weight: 600;line-height: 18px;letter-spacing: -0.07px;}
.seller-inner-tab-wrap .post-cards-wrap .post-card .img-wrap .content-wrap-live{position: absolute;bottom: 0;left: 0;margin: 0 15px 15px 15px;}
.seller-inner-tab-wrap .post-cards-wrap .post-card .img-wrap .content-wrap-live .live-title{display: block;color: #FFF;font-size: 18px;font-weight: 600;line-height: 1.2;}
.seller-inner-tab-wrap .post-cards-wrap .post-card .img-wrap .content-wrap-live .live-content{display: block;color: #FFF;font-size: 14px;font-weight: 400;line-height: 1.4;font-family: 'Poppins' !important;}
.seller-inner-tab-wrap .post-cards-wrap .post-card .img-wrap .content-wrap-product{position: absolute;bottom: 0;left: 0;max-height: 185px;border-radius: 10px;background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 3.02%, #000 100%);height: 100%;width: 100%;}
.seller-inner-tab-wrap .post-cards-wrap .post-card .img-wrap .content-wrap-product .product-title{display: block;color: #FFF;font-size: 28px;font-weight: 700;line-height: 1.2;padding: 90px 18px 0 18px;width: 100%;}
.seller-inner-tab-wrap .post-cards-wrap .post-card .img-wrap .content-wrap-product .product-content{display: block;color: rgba(255, 255, 255, 0.80);font-size: 20.5px;font-weight: 400;line-height: 1.2;padding: 10px 18px;width: 100%;}
.seller-inner-tab-wrap .post-cards-wrapp .postInnerContainer {width: 100%; display: flex; flex-wrap: wrap;}
.seller-inner-tab-wrap .post-cards-wrapp  {width: 100%; display: flex;}
.bargain-card-wrap .bargain-card .person-detail .content-wrap .content .collaborate-title{display: block;font-size: 16px;color: #444;font-weight: 500;line-height: 15px;}
.bargain-card-wrap .bargain-card .person-detail .content-wrap .content .collaborate-detail{font-size: 12px;font-weight: 400;line-height: 11px;display: block;color: #BEBEBE;overflow: hidden;white-space: nowrap;margin-top: 8px;}
.bargain-card-wrap .bargain-card .collaborate-content-wrap{margin: 17px 0;}
.bargain-card-wrap .bargain-card .collaborate-content-wrap .collaborate-content{color: rgba(68, 68, 68, 0.80);font-size: 14px;font-weight: 400;line-height: 1.5;}

.custom-dialog .dialog-content .inner-wrap .business-facility{position: relative;width: 71px;height: 71px;min-width: 71px;display: flex;}
.custom-dialog .dialog-content .inner-wrap .business-facility img{width: 100%;height: 100%;}
.custom-dialog .dialog-content .inner-wrap .business-facility .business-text{position: absolute;color: #444;font-size: 6.327px;font-weight: 500;line-height: 1.2;margin-top: 37px;margin-left: 21px;}
.custom-dialog .dialog-content .inner-wrap .business-facility-title{display: block;color: #444;font-size: 16px;font-weight: 600;}
.custom-dialog .dialog-content .inner-wrap .business-facility-content{display: block;color: #555;font-size: 14px;font-weight: 400;margin-top: 12px;}

.MuiSwitch-root.io-switch-switchBase {
    width: 49px;
    height: 27px;
    display: inline-flex;
    padding: 0;
    z-index: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    vertical-align: middle;
    border-radius: 25px;
}
.io-switch-switchBase .MuiSwitch-switchBase{
    top: -5px;
    left: -4px;
    color: #fafafa;
    z-index: 1;
    position: absolute;
    transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.io-switch-switchBase .PrivateSwitchBase-root-30 {
    padding: 2px;
}

.io-switch-switchBase .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
    background-color: #F5EA16;
}

.io-switch-switchBase .MuiSwitch-colorSecondary.Mui-checked{
    color: #333333;
}

.custom-form-group.shipping-group{display: flex;align-items: center;}
.custom-form-group.shipping-group .shipping-title{color: #444;font-size: 16px;font-weight: 600;line-height: 1.2;}
.custom-form-group.shipping-group .shipping-content{color: #555;font-size: 14px;font-weight: 400;line-height: 1.2;margin-top: 8px;margin-bottom: 10px;}

.custom-form-group .upload{position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;cursor:pointer;z-index:1}

.custom-form-group .file-success-wrap{display: flex; align-items: center;}
.custom-form-group .file-details{max-width: 100%;width: 100%;}
.custom-form-group .file-details span{display: block; margin: 0 12px;}
.custom-form-group .file-details span.file-name{color: #444;font-size: 16px;font-weight: 500;}
.custom-form-group .file-details span.file-size{color: #444;font-size: 12px;font-weight: 300;}
.custom-form-group .file-details .file-close-wrap{display: flex;justify-content: space-between;}
.file-edit-wrap{display: flex;justify-content: space-between; align-items: center; background-color: yellow; padding: 16px; border-top-left-radius: 24px;  border-top-right-radius: 24px;}
.custom-form-group .file-success-wrap.dashed-border{border: 1px dashed rgba(68, 68, 68, 0.50); padding: 15px;}
.custom-dialogs .dialog-contents {
    padding: 30px;
    border-radius: 16px;
    width: 600px;
    height:auto;
  }
  
  .custom-dialogs .dialog-contents .dialog-titles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #000;
    font-size: 20px;
    margin-bottom: 8px;
    border-bottom: 1px solid #eee;
  }
  
  .custom-dialogs .dialog-contents .list-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #eee;
  }
  
  .custom-dialogs .dialog-contents .list-items .avatars {
    margin-right: 12px;
    width: 48px;
    height: 48px;
  }
  
  .custom-dialogs .dialog-contents .list-items .texts {
    flex-grow: 1;
  }
  
  .custom-dialogs .dialog-contents .list-items .texts .primary-texts {
    font-size: 16px;
    font-weight: 600;
    color: #444;
  }
  
  .custom-dialogs .dialog-contents .list-items .texts .secondary-texts {
    font-size: 14px;
    color: #555;
  }
  
  .custom-dialogs .dialog-contents .list-items .remove-buttons {
    border: 1px solid #FFD700; /* Yellow border */
    color: #FFD700;
    border-radius: 20px;
    text-transform: none;
    padding: 4px 16px;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
  }
  
  .custom-dialogs .dialog-contents .close-icons {
    color: #444;
  }
  
.auto-complete-list .MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd{
    padding-right: 9px !important;
}

.horizontalLine {
    border-bottom: 1px solid yellow;
    margin-bottom: 20px;
}

.outerWrapperContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 5px 5px;
}

.outerWrapperContainer:hover, .outerWrapperContainer1:hover {
    background: gainsboro;
    border-radius: 5px;
}

.outerWrapperContainer1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
    padding: 5px 5px;
}

.imageClass {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 15px
}

.businessNameHeading {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.businessTypeHeading {
    color: #393939;
    font-size: 16px;
    font-weight: 400;
}

.rightTick {
    height: 25px;
    width: 25px;
    border-radius: 50%;
}

.modalContainer {
    display: 'flex';
    padding: 16px;
    align-items: 'center';
    justify-content: 'center';
    left: 50%;
    top: 50%;
}

.paper {
    width: 400px;
    height: auto;
    background-color: #fff;
    border: '2px solid #000';
    box-shadow: 5px;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.paper h3 {
    margin: 0;
}

.service-offered-main-div .service-card{width: 100%; display: flex; flex-direction: column; margin: 20px 0; border-radius: 24px; box-shadow: 0px 8px 12px 0px #0000000F, 0px 4px 8px 9px #00000008; color: #444444;}
.service-offered-main-div .service-card .service-card__title{font-weight: 500; font-size: 20px; color: black;}
.service-offered-main-div .service-card .service-card__meta{display: flex; gap: 10px; justify-content: space-between;}
.service-offered-main-div .service-card .service-card__action{display: flex; flex-direction: column; align-items: center; gap: 10px;}
.service-offered-main-div .service-card .service-card__action>*:first-child{font-weight: 600; font-size: 14px; color: black;}
.service-offered-main-div .service-card .service-card__action>*:last-child{font-weight: 400;}
.new-insta-section{padding: 0 24px;}
.service-card__content{margin-bottom: 16px;}

.service-create-main-div .right-inner{width: clamp(650px,50%,800px); display: flex; flex-direction: column; gap: 17px;}
.service-create-main-div .service-card {display: flex; flex-direction: column; border: 1px solid #E1E1E1; border-radius: 24px; padding: 16px; gap: 16px;}
.service-create-main-div .service-card .service-card__heading{font-weight: 500; font-size: 20px; color: black; cursor: pointer; display: contents;}
.service-create-main-div .service-card .service-card__heading:disabled{font-weight: 500; font-size: 20px; color: rgb(140, 135, 135); cursor: pointer; display: contents;}
.service-create-main-div .service-card .service-card__label{font-weight: 400; font-size: 12px; color: #787878; margin-bottom: 4px;}
.service-create-main-div .service-card .service-card__input{width: 100%; border: 1px solid #E1E1E1; font-size: 14px; font-weight: 400; font-size: 16px; padding: 14px 8px; border-radius: 10px;}
.service-create-main-div .service-card .service-card__description>*:last-child{font-weight: 400; font-size: 12px; color: #444444; text-align: right; margin-top: 4px;}
.service-create-main-div .service-card .input__inc__root{ padding: 14px 20px; display: flex; justify-content: space-between;}
.service-create-main-div .service-card .input__inc{ display: flex; justify-content: space-between; width: 10rem; gap: 5px; color: black;}
.service-create-main-div .service-card .input__inc>*{width: 2rem; border: 2px solid black; border-radius: 6px; text-align: center; background-color: transparent;}
.service-create-main-div .service-card .input__inc>input{width: 3rem; border: none;}
.service-create-main-div .button-clear{width: 100%; border: none; background-color: transparent; color: #2E2E2E; font-weight: 600; font-size: 14px; text-align: center; padding: 16px; cursor: pointer;}
.service-create-main-div .button-clear:disabled{opacity: 0.5;}
.service-create-main-div hr{width: 100%; border-top: 1px solid yellow; margin: 0;}
.service-create-main-div .card--warning{width: 100%; border: 1px solid #E1E1E1; padding: 12px 8px; border-radius: 10px; display: flex; align-items: center; gap: 8px;}
.service-create-main-div .button-create-active{width: 100%; border: none; background-color: #F5EA16; color: #2E2E2E; font-weight: 600; font-size: 14px; padding: 16px; border-radius: 100px; text-align: center; cursor: pointer;}
.service-create-main-div .button-create-inactive{width: 100%; border: none; background-color: #F1F5F9; color: #64748B; font-weight: 600; font-size: 14px; padding: 16px; border-radius: 100px; text-align: center;}
.button-create-service{max-width: clamp(400px,50%,600px); border: none; background-color: #F5EA16; color: #2E2E2E; font-weight: 600; font-size: 14px; padding: 10px 16px; border-radius: 100px; text-align: center; cursor: pointer;}
.button-create-service:disabled{max-width: clamp(400px,50%,600px); border: none; background-color: #cecdc1; color: #2E2E2E; font-weight: 600; font-size: 14px; padding: 10px 16px; border-radius: 100px; text-align: center; cursor: no-drop;}
.error-message{ color: red; font-size: 12px;}
.service-card__description .error-message-container{display: flex; justify-content: space-between;}

@media (max-width:1399px) {
    .main-div {margin-top: 65px;}
}

@media (max-width:1180px) {
    .main-div {margin-top: 65px;}
}

@media (max-width:1024px) {
    .main-div {margin-top: 65px;}
    .banner-section-seller{margin:0 0 20px}
}

@media (max-width:991px) {
    .main-div {margin-top: 65px;}
    .following-section-seller .stories-highlights-wrap .story:not(:last-child){
        margin-right: 20px;
    }
    .following-section-seller .stories-highlights-wrap .story-outer:not(:last-child){
        margin-right: 20px;
    }    
    .tab-user-business-section .tabs-content .user-bio-wrap{flex-direction: column;}
    .tab-user-business-section .tabs-content .user-bio-wrap .user-list{flex: 0 0 100%; max-width: 100%;}
    .tab-user-business-section .tabs-content .user-bio-wrap .bio-list{margin: 20px 0; flex: 0 0 100%; max-width: 100%;}
    .tab-user-business-section .tabs-content .user-bio-wrap .bio-list .inner-wrap{margin: 0;}
    .following-section-seller .business-service-wrap .business-facility-main:not(:last-child){margin-right: 30px;}
    .tab-user-business-section .tabs-content .seller-user-password .editable-form-group.column{max-width:100%;width: 100%;}
    .tab-user-business-section .tabs-content .seller-account-removal .editable-form-group.column{max-width: 100%; width: 100%;}
    .tab-user-business-section .tabs-content .seller-line{max-width: 100%;}
    .tab-user-business-section .tabs-content .banking-legal-wrap{flex-direction: column;}
    .tab-user-business-section .tabs-content .banking-legal-wrap .left-side-wrap{flex: 0 0 100%; max-width: 100%;}
    .tab-user-business-section .tabs-content .banking-legal-wrap .shipping-details{flex: 0 0 100%; max-width: 100%;padding-top: 24px;}
}

@media (max-width:767px) {
    .main-div {margin-top: 65px;}
    .banner-section-seller .banner-img-wrap-seller .edit-icon{right:10px}
    .banner-section-seller .profile-detail-seller .profile-description-wrap{padding-right:10px}
    .banner-section-seller .profile-detail-seller .profile-description-wrap .name{font-size:16px;line-height:20px}
    .user-list .editable-form-group .editable-label{max-width: 450px; width: 100%;}
    .following-section-seller .following-followers-wrap .title{font-size: 16px;}
    .following-section-seller .following-followers-wrap .title-number-wrap:not(:last-child){margin-right: 25px;}
    .tab-user-business-section .tabs-content .banking-legal-wrap .banking-list-wrap .banking-list .editable-form-group .plus-edit-icon{display: flex;justify-content: space-between;}
}

@media (max-width:575px) {
    .main-div {margin-top: 65px;}
    .banner-section-seller .profile-detail-seller .profile-photo-seller-wrap-seller{max-width:70px;flex:0 0 70px;height:70px;margin-right:20px}
    .user-list .editable-form-group .editable-label{min-width: 300px;}
}

@media (max-width:374px) {
    .main-div {margin-top: 65px;}
    .user-list .editable-form-group .editable-label{min-width: 250px;}
    .tab-user-business-section .tabs-content .banking-legal-wrap .banking-list-wrap .banking-list .editable-form-group{max-width: 250px;width: 100%;}
}

.confirmation-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    margin: auto;
    position: relative;
    top: 45%;
}

.confirmation-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.confirm-btn, .cancel-btn {
    padding: 10px;
    cursor: pointer;
}

.confirm-btn {
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px
}

.cancel-btn {
    background-color: grey;
    color: white;
    border: none;
    border-radius: 5px
}
